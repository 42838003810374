import React from 'react';
import { Link } from 'react-router-dom';
import profile_pic from '../../images/me.png';

import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={profile_pic} alt="" />
      </Link>
      <header>
        <h2>Marco Pianta</h2>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p>Hi, I&apos;m Marco. I am a Computer Science Engineer graduated from <a href="https://www.polimi.it/"><i>Politecnico di Milano</i></a>.
        I focused my studies on Cybersecurity, and my skills extend across various areas, including Cybersecurity, Artiﬁcial Intelligence, Machine Learning, Software Engineering, and IoT.
        Feel free to browse my site and if you want to know more, contact me!
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">&copy; Marco Pianta <Link to="/">marcopianta.com</Link>.</p>
    </section>
  </section>
);

export default SideBar;
